import getCurrentDate from 'helpers/date_time/getCurrentDate';
import getCurrentDateInAmericaTimeZone from 'helpers/date_time/getCurrentDateInAmericaTimeZone';

// Adyen Script and Style Links
export const ADYEN_HOST_URL = `https://checkoutshopper-${process.env.ADYEN_ENVIRONMENT}.adyen.com`;
export const ADYEN_SCRIPT_LINK = `${ADYEN_HOST_URL}/checkoutshopper/sdk/4.7.2/adyen.js`;
export const ADYEN_CSS_LINK = `${ADYEN_HOST_URL}/checkoutshopper/sdk/4.7.2/adyen.css`;
export const SHAREASALE_SCRIPT_LINK = 'https://www.dwin1.com/19038.js';
export const AWIN_MASTER_TAG_SCRIPT_SRC = 'https://www.dwin1.com/65760.js';

// Inicis Hosts, Script and Style Links
export const INICIS_HOST_URL_DEV = `https://stgstdpay.inicis.com/stdjs`;
export const INICIS_HOST_URL_PROD = `https://stdpay.inicis.com/stdjs`;
export const INICIS_PAY_SCRIPT_LINK =
    process.env.APP_ENV.toLowerCase() === 'development' ||
    process.env.KGINICIS_STOREID === 'INIpayTest'
        ? `${INICIS_HOST_URL_DEV}/INIStdPay.js`
        : `${INICIS_HOST_URL_PROD}/INIStdPay.js`;
export const INICIS_MOBILE_PAY_SCRIPT_LINK = 'https://mobile.inicis.com/smart/payment/';
export const INICIS_MOBILE_AUTORENEWAL_PAY_SCRIPT_LINK =
    'https://inilite.inicis.com/inibill/inibill_card.jsp';
export const INICIS_MOBILE_VIRTUAL_LINK = `${
    process.env.APP_ENV.toLowerCase() === 'development'
        ? process.env.INICIS_VBANK_NOTI_DOMAIN
            ? process.env.INICIS_VBANK_NOTI_DOMAIN
            : 'https://dev.123rf.com'
        : 'https://www.123rf.com'
}/apicore-payments/inicis/webhooks-listener/mobile`;

// Payment Methods
export const PAYMENT_METHOD = {
    bankin: 'bankin',
    card: 'creditcard',
    ideal: 'ideal',
    paypal: 'paypal',
    sepa: 'sepa',
    sofort: 'sofort',
    xpay: 'xpay',
    inicis: 'inicis',
    tazapay: 'tazapay',
};
export const PAYMENT_METHODS_EXC_PAYPAL_INICIS = [
    PAYMENT_METHOD.card,
    PAYMENT_METHOD.sepa,
    PAYMENT_METHOD.sofort,
    PAYMENT_METHOD.ideal,
];
export const PAYMENT_METHODS_EXC_PAYPAL_CARD = [
    PAYMENT_METHOD.sepa,
    PAYMENT_METHOD.sofort,
    PAYMENT_METHOD.ideal,
    PAYMENT_METHOD.inicis,
];
export const ALL_PAYMENT_METHODS = [
    PAYMENT_METHOD.card,
    PAYMENT_METHOD.paypal,
    PAYMENT_METHOD.sepa,
    PAYMENT_METHOD.sofort,
    PAYMENT_METHOD.ideal,
    PAYMENT_METHOD.inicis,
    PAYMENT_METHOD.tazapay,
];

// Not Ready Payment Method in Payment-ui
export const PAYMENT_METHOD_REDIRECT = ['xpay'];

// Adyen Styling
export const ADYEN_STYLE = {
    base: {
        fontSize: '14px',
        color: '#333333',
        caretColor: '#333333',
    },
    placeholder: {
        color: '#767676',
    },
    error: {
        color: '#E71D36',
    },
};

// Adyen Placeholders
export const ADYEN_PLACEHOLDERS = {
    'en-US': {
        'creditCard.cvcField.placeholder.3digits': 'CVV',
        'creditCard.cvcField.placeholder.4digits': 'CVV',
    },
};

// List of Adyen Cards
export const ADYEN_CARDS_LIST = ['mc', 'visa', 'amex', 'cartebancaire', 'jcb'];

// Adyen Result Codes
export const ADYEN_RESULT_CODES = {
    authorised: 'Authorised',
    redirectShopper: 'RedirectShopper',
    refused: 'Refused',
};

// Sepa Result Codes
export const SEPA_RESULT_CODES = {
    authorised: 'Authorised',
    received: 'Received',
};

// Sofort Result Codes
export const SOFORT_RESULT_CODES = {
    authorised: 'Authorised',
    received: 'Received',
    redirectShopper: 'RedirectShopper',
};

// Ideal Result Codes
export const IDEAL_RESULT_CODES = {
    authorised: 'Authorised',
    received: 'Received',
    redirectShopper: 'RedirectShopper',
};

// Inicis Result Codes
export const INICIS_RESULT_CODES = {
    authorised: '0000',
    mobileAuthorised: '00',
};

// Payment details validation types
export const VALIDATION_TYPES = {
    tax: 'tax',
    promocode: 'promocode',
};

// List of packages
export const PRODUCT_PACKAGE = {
    subs: 'subs',
    credits: 'credits',
    multilogin: 'multilogin',
    psub: 'psub',
    misc: 'misc',
};

// List of product types
export const PRODUCT_TYPES = {
    default: 'default',
    multilogin: 'multilogin',
    proforma: 'proforma',
    termsgrant: 'termsgrant',
    cancellation: 'cancellation',
};

export const TAX_EXEMPTION_SUPPORTED_COUNTRIES = [
    'AT',
    'BE',
    'BG',
    'CY',
    'CZ',
    'DE',
    'DK',
    'EE',
    'ES',
    'FI',
    'FR',
    'FX',
    'GB',
    'GR',
    'HR',
    'HU',
    'IE',
    'IT',
    'LT',
    'LU',
    'LV',
    'MT',
    'PL',
    'PT',
    'RO',
    'SE',
    'SI',
    'SK',
];

export const VAT_NUMBER_FIELD_SUPPORTED_COUNTRIES = ['NL', 'TW'];

// List of countries which opt out of autorenewal by default
export const AUTORENEW_OFF_COUNTRIES = [
    'AT',
    'BE',
    'BG',
    'CY',
    'CZ',
    'DE',
    'DK',
    'EE',
    'ES',
    'FI',
    'FR',
    'FX',
    'GR',
    'HR',
    'HU',
    'IT',
    'LT',
    'LU',
    'LV',
    'MT',
    'NL',
    'PL',
    'PT',
    'RO',
    'SE',
    'SI',
    'SK',
];

// List of currencies which accept paypal gateway
export const PAYPAL_ACCEPTED_CURRENCIES = [
    'AUD',
    'BRL',
    'CAD',
    'CZK',
    'DKK',
    'EUR',
    'EURO',
    'GBP',
    'HKD',
    'IDR',
    'INR',
    'JPY',
    'PLN',
    'RUB',
    'SEK',
    'SGD',
    'TWD',
    'USD',
    'ZAR',
];

// List of countries which reject paypal gateway
export const PAYPAL_REJECTED_COUNTRIES = ['TR', 'TW'];

// List of countries which accept bank-in
export const BANK_IN_COUNTRIES = [
    'CZ',
    'ES',
    'FR',
    'HK',
    'JP',
    'MY',
    'SG',
    'SK',
    'TW',
    'ZA',
    'RU',
];

// Country codes for countries that are included in European Enion, adyen transactions should be routed to 123RF_EU merchant account
export const ADYEN_EU_COUNTRIES = [
    'AT',
    'BE',
    'BG',
    'CY',
    'CZ',
    'DE',
    'DK',
    'EE',
    'ES',
    'FI',
    'FR',
    'GR',
    'IE',
    'IT',
    'LT',
    'LU',
    'LV',
    'MT',
    'NL',
    'PT',
    'SE',
    'SI',
    'SK',
];

// Country codes for countries that are not in European Enion, adyen transactions should be routed to 123RFCOM_EU merchant account
export const ADYEN_EH_COUNTRIES = [
    'CH',
    'FX',
    'GB',
    'HR',
    'HU',
    'IS',
    'NO',
    'PL',
    'RO',
    'RS',
    'RU',
    'TR',
];

// List of PSUB plans that serve monthly
export const PSUB_MONTHLY_PLANS = ['psubmth', 'psubmthgg', 'psubmtham', 'inreg', 'inspc'];

// List of Petite plans that serve rollover
export const SUBS_ROLLOVER_PLANS = ['petite1', 'petite12'];

export const TRACKING_DATA_COUNTRIES = [
    'AE',
    'AT',
    'AU',
    'BE',
    'BR',
    'CA',
    'CH',
    'DE',
    'ES',
    'FR',
    'GB',
    'HK',
    'IL',
    'IT',
    'JP',
    'KR',
    'MX',
    'MY',
    'NL',
    'NZ',
    'PL',
    'RU',
    'SG',
    'TH',
    'TR',
    'TW',
    'US',
];

// Limit to Germany for now
export const SEPA_ACCEPTED_COUNTRIES = [
    // 'AT',
    // 'BE',
    // 'BG',
    // 'CH',
    // 'CY',
    // 'CZ',
    'DE',
    // 'DK',
    // 'EE',
    // 'ES',
    // 'FI',
    // 'FR',
    // 'GB',
    // 'GR',
    // 'HR',
    // 'HU',
    // 'IE',
    // 'IS',
    // 'IT',
    // 'LI',
    // 'LT',
    // 'LU',
    // 'LV',
    // 'MC',
    // 'MT',
    // 'NL',
    // 'NO',
    // 'PL',
    // 'PT',
    // 'RO',
    // 'SE',
    // 'SI',
    // 'SK',
];

// Limit to Germany for now
export const SOFORT_ACCEPTED_COUNTRIES = [
    // 'AT',
    // 'BE',
    // 'BG',
    // 'CH',
    // 'CY',
    // 'CZ',
    'DE',
    // 'DK',
    // 'EE',
    // 'ES',
    // 'FI',
    // 'FR',
    // 'GB',
    // 'GR',
    // 'HR',
    // 'HU',
    // 'IE',
    // 'IS',
    // 'IT',
    // 'LI',
    // 'LT',
    // 'LU',
    // 'LV',
    // 'MC',
    // 'MT',
    // 'NL',
    // 'NO',
    // 'PL',
    // 'PT',
    // 'RO',
    // 'SE',
    // 'SI',
    // 'SK',
];

/* START: IDEAL Configuration */
// Limit to Netherlands for now
export const IDEAL_ACCEPTED_COUNTRIES = [
    // 'AT',
    // 'BE',
    // 'BG',
    // 'CH',
    // 'CY',
    // 'CZ',
    // 'DE',
    // 'DK',
    // 'EE',
    // 'ES',
    // 'FI',
    // 'FR',
    // 'GB',
    // 'GR',
    // 'HR',
    // 'HU',
    // 'IE',
    // 'IS',
    // 'IT',
    // 'LI',
    // 'LT',
    // 'LU',
    // 'LV',
    // 'MC',
    // 'MT',
    'NL',
    // 'NO',
    // 'PL',
    // 'PT',
    // 'RO',
    // 'SE',
    // 'SI',
    // 'SK',
];

export const TAZAPAY_ACCEPTED_COUNTRIES = [
    'IN',
    'BR',
];

export const IDEAL_TEST_PAYMENT_ISSUERS = [
    { issuerName: 'Test Issuer', issuerID: '1121' },
    { issuerName: 'Test Issuer 2', issuerID: '1151' },
    { issuerName: 'Test Issuer 3', issuerID: '1152' },
    { issuerName: 'Test Issuer 4', issuerID: '1153' },
    { issuerName: 'Test Issuer 5', issuerID: '1154' },
    { issuerName: 'Test Issuer 6', issuerID: '1155' },
    { issuerName: 'Test Issuer 7', issuerID: '1156' },
    { issuerName: 'Test Issuer 8', issuerID: '1157' },
    { issuerName: 'Test Issuer 9', issuerID: '1158' },
    { issuerName: 'Test Issuer 10', issuerID: '1159' },
    { issuerName: 'Test Issuer Refused', issuerID: '1160' },
    { issuerName: 'Test Issuer Pending', issuerID: '1161' },
    { issuerName: 'Test Issuer Cancelled', issuerID: '1162' },
];

export const IDEAL_LIVE_PAYMENT_ISSUERS = [
    { issuerName: 'ABN AMRO', issuerID: '0031' },
    { issuerName: 'ASN Bank', issuerID: '0761' },
    { issuerName: 'bunq', issuerID: '0802' },
    { issuerName: 'Handelsbanken', issuerID: '0804' },
    { issuerName: 'ING Bank', issuerID: '0721' },
    { issuerName: 'Knab', issuerID: '0801' },
    { issuerName: 'Rabobank', issuerID: '0021' },
    { issuerName: 'Regiobank', issuerID: '0771' },
    { issuerName: 'Revolut', issuerID: '0805' },
    { issuerName: 'SNS Bank', issuerID: '0751' },
    { issuerName: 'Triodos Bank', issuerID: '0511' },
    { issuerName: 'Van Lanschot Bankiers', issuerID: '0161' },
];

/* END: IDEAL Configuration */

export const YEARLY_PLANS = {
    petite: {
        yearlyUpfrontPay: 'petite12',
        yearlyMonthlyPay: 'i-petite12',
    },
    demi: {
        yearlyUpfrontPay: 'demi12',
        yearlyMonthlyPay: 'i-demi12',
    },
    tall: {
        yearlyUpfrontPay: 'tall12',
        yearlyMonthlyPay: 'i-tall12',
    },
    regular: {
        yearlyUpfrontPay: 'regular12',
        yearlyMonthlyPay: 'i-regular12',
    },
    grande: {
        yearlyUpfrontPay: 'grande12',
        yearlyMonthlyPay: 'i-grande12',
    },
};

export const MIN_PRICE_TO_SHOW_OTP_NOTE = 500;

export const PLUS_PLANS = {
    plusMonthly: 'plus1',
    plusYearly: 'plus12',
    plusYearlySpecial: 'splus12',
    plusThreeYears: 'plus36',
    plusFreeTrial: 'fplus7',
    plusBusinessMonthly: 'plusb1',
    plusBusinessYearly: 'plusb12',
};

export const PLUS_BUSINESS_PLANS = {
    plusBusinessMonthly: 'plusb1',
    plusBusinessYearly: 'plusb12',
};

export const PREMIUM_PLANS = {
    petite: { monthly: 'petite1', yearly: 'petite12' },
    demi: { monthly: 'demi1', yearly: 'demi12' },
    tall: { monthly: 'tall1', yearly: 'tall12' },
    regular: { monthly: 'regular1', yearly: 'regular12' },
    grande: { monthly: 'grande1', yearly: 'grande12' },
};

export const VALID_SUBS_PLANS = [
    'petite1',
    'petite12',
    'i-petite12',
    'tall1',
    'tall12',
    'i-tall12',
    'regular1',
    'regular12',
    'i-regular12',
    'demi1',
    'demi12',
    'i-demi12',
    'grande1',
    'grande12',
    'i-grande12',
    'plus1',
    'plus12',
    'splus12',
    'plus36',
    'fplus7',
    'plusb1',
    'plusb12',
];

export const BOGO_PROMO = {
    start: '2023-06-12 00:00:00',
    end: '2023-06-19 00:00:00',
    promocode: 'SHAREPLUS',
};
const getEventPromoValidity = () => {
    const currentDate = getCurrentDateInAmericaTimeZone(getCurrentDate());
    const eventStartDate = '2024-03-19';
    const eventEndDate = '2024-03-22';
    return eventStartDate <= currentDate && eventEndDate >= currentDate;
};
export const PLANS_WITH_PROMOCODE_PAYMENT = [
    { plan: 'fplus7', promocode: 'freeplus', isValid: true },
];

export const AWIN_AWC_COMMISSION_GROUP = process.env.AWIN_AWC_COMMISSION_GROUP || 'DEFAULT';
export const AWIN_AWC_TEST_MODE_ENABLED =
    process.env.APP_ENV.toLowerCase() === 'development' ? '1' : '0';

export const ALLOWED_PAYMENT_METHODS_PLUS_BUNDLE = [PAYMENT_METHOD.card];
