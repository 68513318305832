import { Metadata } from '123rf-ui-core/components';
import { useMemo } from 'react';
import Head from 'next/head';
import intl from 'react-intl-universal';
import PropTypes from 'prop-types';

// Pre-defined Components
import { PAGE_FOLDERS as pageFolders } from 'config/constants';
import { SHARED_IMAGES as images } from 'config/images/shared';

const AppHead = (props) => {
    const { router, hostName, lang, userId, countryOrigin } = props;
    const { asPath, pathname } = router;
    const subDomain = lang === 'en' ? 'www' : lang;

    let metaTitle = '';
    let metaDesc = '';
    let metaKeywords = '';
    let schemaName = '';
    switch (pathname) {
        case `/${pageFolders.products}`:
            metaTitle = intl.get('PRODUCTS.Products_MetaTitle');
            metaDesc = intl.get('PRODUCTS.Products_MetaDescription');
            metaKeywords = intl.get('PRODUCTS.Products_MetaKeywords');
            schemaName = `123RF ${intl.get('PRODUCTS.ChoosePlan_Title')?.replace(/<br>/g, ' ')}`;
            break;
        case `/${pageFolders.enterprise}`:
            metaTitle = intl.get('PRODUCTS.Products_MetaTitle');
            metaDesc = intl.get('PRODUCTS.Products_MetaDescription');
            metaKeywords = intl.get('PRODUCTS.Products_MetaKeywords');
            schemaName = `123RF ${intl.get('PRODUCTS.ChoosePlan_Title')?.replace(/<br>/g, ' ')}`;
            break;
        case `/${pageFolders.checkout}/payment_status`:
            metaTitle = intl.get('CHECKOUT.PaymentStatus_MetaTitle');
            metaDesc = intl.get('CHECKOUT.PaymentStatus_MetaDesc');
            schemaName = '123RF Payment Status';
            break;
        case `/${pageFolders.checkout}`:
            metaTitle = intl.get('CHECKOUT.Checkout_MetaTitle');
            metaDesc = intl.get('CHECKOUT.Checkout_MetaDesc');
            metaKeywords = intl.get('CHECKOUT.Checkout_MetaKeywords');
            schemaName = `123RF ${intl.get('CHECKOUT.Checkout_SecureCheckout')}`;
            break;
        default:
            break;
    }
    const metaSchema = {
        '@context': 'https://schema.org/',
        '@type': 'WebSite',
        ...(schemaName && { name: schemaName }),
        url: `https://${subDomain}.123rf.com`,
        mainEntityOfPage: `https://${subDomain}.123rf.com${asPath}`,
        ...(metaDesc && { description: metaDesc }),
        ...(metaKeywords && { keywords: metaKeywords }),
    };
    const siteURL = `https://${hostName}${asPath}`;

    const use10xLaunch = useMemo(() => {
        const isCheckout = pathname === `/${pageFolders.checkout}`;
        return !userId && countryOrigin === 'US' && isCheckout;
    }, [userId, countryOrigin, pathname]);

    return (
        <Head>
            <meta charSet="utf-8" />
            <meta name="viewport" content="width=device-width,initial-scale=1.0" />
            {Metadata({ append: [metaSchema] })}
            <link rel="icon" href={images.favicon} />
            {metaTitle && <title key="meta_title">{metaTitle}</title>}
            {metaDesc && <meta name="description" content={metaDesc} />}
            {metaKeywords && <meta name="keywords" content={metaKeywords} />}
            <meta name="apple-mobile-web-app-capable" content="yes" />
            {metaTitle && <meta property="og:site_name" content={metaTitle} />}
            {metaTitle && <meta property="og:title" content={metaTitle} />}
            {metaDesc && <meta property="og:description" content={metaDesc} />}
            <meta property="og:image" content={images.blackBGLogo} />
            <meta property="og:type" content="website" />
            <meta property="og:url" content={siteURL} />
            <link rel="mask-icon" href={images.safariPinnedTab} color="#5bbad5" />
            <meta name="robots" content="max-image-preview:large" />
            {use10xLaunch && <script
                src="https://dashboard.10xlaunch.ai/widget"
                data-app-id={process.env.DATA_APP_ID_10X}
                async
                defer
            />}
        </Head>
    );
};

AppHead.propTypes = {
    router: PropTypes.objectOf(PropTypes.shape),
    hostName: PropTypes.string.isRequired,
    lang: PropTypes.string.isRequired,
    userId: PropTypes.string.isRequired,
    countryOrigin: PropTypes.string.isRequired,
};

AppHead.defaultProps = {
    router: {
        pathname: '',
        asPath: '',
    },
};

export default AppHead;
