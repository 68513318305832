/* eslint-disable import/prefer-default-export */
import { images as uiCoreImages } from "123rf-ui-core/assets";

const checkoutAssetsPath = `${process.env.ASSETS_CDN_PUBLIC}/payments/assets/images`;
const indexAssetsPath = `${process.env.ASSETS_CDN_PUBLIC}/index/static/assets`;
const contactCustomerAssetsPath = `${process.env.ASSETS_CDN_PUBLIC}/index/static/assets/contact-customer`;

export const SHARED_IMAGES = {
    blackBGLogo: `${indexAssetsPath}/123rf-logo-blackbg.jpg`,
    chromeLogo: `${indexAssetsPath}/chrome-logo.png`,
    closeLogo: `${indexAssetsPath}/delete.png`,
    edgeLogo: `${indexAssetsPath}/edge-logo.png`,
    error404: `${checkoutAssetsPath}/404.png`,
    favicon: uiCoreImages.faviconIco,
    firefoxLogo: `${indexAssetsPath}/firefox-logo.png`,
    flags: `${contactCustomerAssetsPath}/flags.png`,
    safariLogo: `${indexAssetsPath}/safari-logo.png`,
    safariPinnedTab: `${indexAssetsPath}/safari-pinned-tab.svg`,
    supportCustomerBGDesktop: `${contactCustomerAssetsPath}/support-customer-bg.jpg`,
    supportCustomerBGTablet: `${contactCustomerAssetsPath}/support-customer-bg-tablet.jpg`,
};
